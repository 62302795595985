import axios from "axios";
import qs from "qs";

export {
  validateAndPrepareRowProperties,
  importRowToTwilio,
  assignPhoneNumberToMessagingService,
};

const SECONDARY_CUSTOMER_PROFILE_POLICY_SID =
  "RNdfbf3fae0e1107f8aded0e7cead80bf5";
const A2P_TRUST_PRODUCT_POLICY_SID = "RNb0d4771c2c98518d916a3d4cd70a8f8b";

const defaultRowProperties = {
  TWILIO_ACCOUNT_SID: null,
  TWILIO_AUTH_TOKEN: null,
  Phone_SID: "",
  creator_email: "mike@mechanicadvisor.com",
  friendly_name: null,
  business_identity: "direct_customer",
  business_industry: "AUTOMOTIVE",
  business_name: null,
  business_regions_of_operation: "USA_AND_CANADA",
  business_registration_identifier: "EIN",
  business_registration_number: null,
  business_type: null,
  social_media_profile_urls: "",
  website_url: "",
  business_title: null,
  email: null,
  first_name: null,
  last_name: null,
  job_position: null,
  phone_number: null,
  street: null,
  city: null,
  region: null,
  iso_country: "US",
  postal_code: null,
  company_type: "private",
  hubspot_id: "",
  campaign_website_url: "",
};

const acceptableRowPropertiesValues = {
  business_identity: ["direct_customer"],
  business_type: [
    "Sole Proprietorship",
    "Partnership",
    "Limited Liability Corporation",
    "Co-operative",
    "Non-profit Corporation",
    "Corporation",
  ],
  business_industry: [
    "AUTOMOTIVE",
    "AGRICULTURE",
    "BANKING",
    "CONSUMER",
    "EDUCATION",
    "ENGINEERING",
    "ENERGY",
    "OIL_AND_GAS",
    "FAST_MOVING_CONSUMER_GOODS",
    "FINANCIAL",
    "FINTECH",
    "FOOD_AND_BEVERAGE",
    "GOVERNMENT",
    "HEALTHCARE",
    "HOSPITALITY",
    "INSURANCE",
    "LEGAL",
    "MANUFACTURING",
    "MEDIA",
    "ONLINE",
    "RAW_MATERIALS",
    "REAL_ESTATE",
    "RELIGION",
    "RETAIL",
    "JEWELRY",
    "TECHNOLOGY",
    "TELECOMMUNICATIONS",
    "TRANSPORTATION",
    "TRAVEL",
    "ELECTRONICS",
    "NOT_FOR_PROFIT",
  ],
  business_registration_identifier: [
    "EIN",
    "DUNS",
    "CCN",
    "CN",
    "ACN",
    "CIN",
    "VAT",
    "VATRN",
    "RN",
    "Other", // additional parameter required in this case
  ],
  business_regions_of_operation: [
    "USA_AND_CANADA",
    "LATIN_AMERICA",
    "EUROPE",
    "AFRICA",
    "ASIA",
  ],
  job_position: [
    "Director",
    "GM",
    "VP",
    "CEO",
    "CFO",
    "General_Counsel",
    "Other",
  ],
  company_type: ["private"],
};

const rulesOfRowPropertiesValues = {
  business_registration_number: {
    matchRegex: /\d+/g,
    length: 9,
    additionalProperty: {
      key: "business_registration_identifier",
      value: "EIN",
    },
  },
  phone_number: {
    matchRegex: /\d+/g,
    requiredLength: 10,
    minLength: 9,
    prependChar: "0",
  },
  business_name: {
    matchRegex: /^.*(?=\sdba)/i,
  },
};

let IDCounter = 0;
let activeTasksCounter = 0;
let lastAPICallTimestamp = 0;

const messagingServicesByRowId = {};

function validateAndPrepareRowProperties(row) {
  const id = IDCounter++;

  const providedRowProperties = {};
  for (const [key, value] of Object.entries(row)) {
    if (
      key in defaultRowProperties &&
      value !== "" &&
      value !== null &&
      value !== undefined
    ) {
      providedRowProperties[key] = value;
    }
  }
  const rowProperties = { ...defaultRowProperties, ...providedRowProperties };

  try {
    for (let [key, value] of Object.entries(rowProperties)) {
      if (value === null) {
        throw new Error(`${key} property is missing`);
      }

      value = value.trim();
      rowProperties[key] = value;

      if (
        acceptableRowPropertiesValues[key] &&
        !acceptableRowPropertiesValues[key].includes(value)
      ) {
        throw new Error(
          `${key}=${value} is not acceptable, possible values are: ${JSON.stringify(
            acceptableRowPropertiesValues[key]
          )}`
        );
      }

      if (rulesOfRowPropertiesValues[key]) {
        const {
          additionalProperty,
          matchRegex,
          length,
          minLength,
          requiredLength,
          prependChar,
        } = rulesOfRowPropertiesValues[key];
        if (
          !additionalProperty ||
          rowProperties[additionalProperty.key] === additionalProperty.value
        ) {
          const match = value.match(matchRegex);
          let correctedValue = match ? match.join("") : value;
          if (length && correctedValue.length !== length) {
            throw new Error(
              `${key} validation failed, should be ${length} digits`
            );
          } else if (requiredLength) {
            correctedValue = correctedValue.slice(-requiredLength);
            if (correctedValue.length < requiredLength) {
              if (correctedValue.length < minLength) {
                throw new Error(
                  `${key} validation failed, should be minimum ${minLength} digits`
                );
              }
              const prependStr = prependChar.repeat(
                requiredLength - correctedValue.length
              );
              correctedValue = `${prependStr}${correctedValue}`;
            }
          }
          rowProperties[key] = correctedValue;
        }
      }
    }

    return { id, properties: rowProperties };
  } catch (e) {
    return { id, error: e.message, properties: rowProperties };
  }
}

async function importRowToTwilio(
  id,
  rowProperties = {},
  callback = () => {},
  options = { allowMultipleSecondaryProfiles: false }
) {
  //* check if there are no secondary profile already created
  //* execute all api calls, and send statuses using callback

  try {
    // to avoid Twilio API overload
    await new Promise((res) => setTimeout(res, activeTasksCounter++ * 1000));

    callback({
      id,
      status: "Import started",
    });

    const {
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      Phone_SID,
      creator_email,
      friendly_name,
      business_identity,
      business_industry,
      business_name,
      business_regions_of_operation,
      business_registration_identifier,
      business_registration_number,
      business_type,
      social_media_profile_urls,
      website_url,
      business_title,
      email,
      first_name,
      last_name,
      job_position,
      phone_number,
      street,
      city,
      region,
      iso_country,
      postal_code,
      company_type,
      hubspot_id,
      campaign_website_url,
    } = rowProperties;

    //* check if secondary profile already exists for this subaccount
    const existingProfiles = await getSecondaryCustomerProfiles({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
    });
    if (existingProfiles.length) {
      if (!options.allowMultipleSecondaryProfiles) {
        throw new Error("secondary profile already exists");
      }
      for (const profile of existingProfiles) {
        if (profile.friendly_name === friendly_name) {
          throw new Error(`secondary profile ${friendly_name} already exists`);
        }
      }
    }

    //* start ISV US A2P 10DLC Low Volume Standard Registration
    const secondaryCustomerProfileSID = await createSecondaryCustomerProfile({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      creator_email,
      friendly_name,
    });

    const businessInformationSID =
      await createCustomerProfileBusinessInformation({
        TWILIO_ACCOUNT_SID,
        TWILIO_AUTH_TOKEN,
        friendly_name,
        business_identity,
        business_industry,
        business_name,
        business_regions_of_operation,
        business_registration_identifier,
        business_registration_number,
        business_type,
        social_media_profile_urls,
        website_url,
      });

    const authorizedRepresentativeSID =
      await createCustomerProfileAuthorizedRepresentative({
        TWILIO_ACCOUNT_SID,
        TWILIO_AUTH_TOKEN,
        friendly_name,
        business_title,
        email,
        first_name,
        last_name,
        job_position,
        phone_number,
      });

    const addressSID = await createCustomerProfileAddress({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      friendly_name,
      street,
      city,
      region,
      iso_country,
      postal_code,
    });

    const documentSID = await createCustomerDocument({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      friendly_name,
      addressSID,
    });

    await Promise.all(
      [
        businessInformationSID,
        authorizedRepresentativeSID,
        documentSID,
        "BU18bba094682e7c2daa2f0bed1f056543", // ISV primary business profile
      ].map(async (entitySID) => {
        await assignEntityToCustomerProfile({
          TWILIO_ACCOUNT_SID,
          TWILIO_AUTH_TOKEN,
          secondaryCustomerProfileSID,
          entitySID,
        });
      })
    );

    const {
      status: customerProfileEvaluationStatus,
      results: customerProfileEvaluationResults,
    } = await evaluateSecondaryCustomerProfile({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      secondaryCustomerProfileSID,
    });

    if (customerProfileEvaluationStatus !== "compliant") {
      const failedResult =
        customerProfileEvaluationResults.find((result) => !result.passed) || {};
      const failedField =
        failedResult.fields.find((field) => !field.passed) || {};
      const failureReason =
        failedField.failure_reason || "details not provided";

      throw new Error(
        `failure during customer profile evaluation, ${failureReason}`
      );
    }

    await submitReviewSecondaryCustomerProfile({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      secondaryCustomerProfileSID,
    });

    callback({
      id,
      status: "Secondary Customer Profile created",
    });

    const brandFriendlyName = hubspot_id
      ? `${friendly_name} - HS${hubspot_id}`
      : friendly_name;

    const trustProductSID = await createA2PTrustProduct({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      friendly_name: brandFriendlyName,
      email,
    });

    const trustProductInformationSID =
      await createA2PMessagingProfileInformation({
        TWILIO_ACCOUNT_SID,
        TWILIO_AUTH_TOKEN,
        friendly_name,
        company_type,
      });

    await Promise.all(
      [trustProductInformationSID, secondaryCustomerProfileSID].map(
        async (entitySID) =>
          await assignEntityToTrustProduct({
            TWILIO_ACCOUNT_SID,
            TWILIO_AUTH_TOKEN,
            trustProductSID,
            entitySID,
          })
      )
    );

    const {
      status: trustProductEvaluationStatus,
      results: trustProductEvaluationResults,
    } = await evaluateTrustProduct({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      trustProductSID,
    });

    if (trustProductEvaluationStatus !== "compliant") {
      const failedResult =
        trustProductEvaluationResults.find((result) => !result.passed) || {};
      const failedField =
        failedResult.fields.find((field) => !field.passed) || {};
      const failureReason =
        failedField.failure_reason || "details not provided";

      throw new Error(
        `failure during trust product evaluation, ${failureReason}`
      );
    }

    await submitReviewTrustProduct({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      trustProductSID,
    });

    callback({
      id,
      status: "A2P Trust Product created",
    });

    const brandSID = await createA2PBrand({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      secondaryCustomerProfileSID,
      trustProductSID,
    });

    callback({
      id,
      status: "A2P Brand created",
    });

    let {
      status: brandRegistrationStatus,
      failure_reason: brandRegistrationFailureReason,
    } = await getBrandRegistrationStatus({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      brandSID,
    });

    callback({
      id,
      status: "A2P Brand PENDING",
    });

    while (brandRegistrationStatus === "PENDING") {
      await new Promise((res) => setTimeout(res, 10 * 1000));
      let { status, failure_reason } = await getBrandRegistrationStatus({
        TWILIO_ACCOUNT_SID,
        TWILIO_AUTH_TOKEN,
        brandSID,
      });
      brandRegistrationStatus = status;
      brandRegistrationFailureReason = failure_reason;
    }

    callback({
      id,
      status: `A2P Brand ${brandRegistrationStatus}`,
    });

    if (brandRegistrationStatus !== "APPROVED") {
      throw new Error(
        `A2P Brand registration ${brandRegistrationStatus}, reason ${brandRegistrationFailureReason}`
      );
    }

    const messagingServiceSID = await createMessagingService({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      friendly_name,
    });

    await createA2PCampaign({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      messagingServiceSID,
      brandSID,
      friendly_name,
      campaign_website_url,
    });

    callback({
      id,
      status: `A2P Campaign created`,
    });

    if (Phone_SID) {
      await assignPhoneNumberToMessagingService(
        id,
        rowProperties,
        callback,
        Phone_SID
      );
    } else {
      const phoneNumbers = await getPhoneNumbers({
        TWILIO_ACCOUNT_SID,
        TWILIO_AUTH_TOKEN,
      });

      if (!phoneNumbers.length) {
        callback({
          id,
          status: `COMPLETE, NO Phone Numbers to assign`,
        });
      } else {
        messagingServicesByRowId[id] = messagingServiceSID;
        callback({
          id,
          status: `COMPLETE, Ready to add a Phone Number`,
          phoneNumbers,
        });
      }
    }

    activeTasksCounter--;
  } catch (e) {
    activeTasksCounter--;
    callback({
      id,
      status: "error",
      error: e.message,
    });
  }
}

async function assignPhoneNumberToMessagingService(
  rowId,
  rowProperties = {},
  callback = () => {},
  phoneNumberSid
) {
  try {
    const { TWILIO_ACCOUNT_SID, TWILIO_AUTH_TOKEN } = rowProperties;

    callback({
      id: rowId,
      status: "Adding Phone Number to Messaging Service",
    });

    const number = await addPhoneNumberToMessagingService({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      phoneNumberSid,
      messagingServiceSid: messagingServicesByRowId[rowId],
    });

    callback({
      id: rowId,
      status: `COMPLETE, phone number: ${number}`,
    });
  } catch (e) {
    callback({
      id: rowId,
      status: "error",
      error: e.message,
    });
  }
}

// Twilio API methods

async function getSecondaryCustomerProfiles({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
}) {
  try {
    const res = await axios({
      method: "get",
      url: "https://trusthub.twilio.com/v1/CustomerProfiles",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({ PolicySid: SECONDARY_CUSTOMER_PROFILE_POLICY_SID }),
    });

    return res.data.results;
  } catch (e) {
    throw new Error(
      `error during api call getSecondaryCustomerProfiles, ${e.message}, are twilio sid and token correct?`
    );
  }
}

async function createSecondaryCustomerProfile({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  friendly_name,
  creator_email,
}) {
  try {
    const res = await axios({
      method: "post",
      url: "https://trusthub.twilio.com/v1/CustomerProfiles",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        PolicySid: SECONDARY_CUSTOMER_PROFILE_POLICY_SID,
        FriendlyName: friendly_name,
        Email: creator_email,
      }),
    });

    return res.data.sid;
  } catch (e) {
    throw new Error(
      `error during api call createSecondaryCustomerProfile, ${e.message}`
    );
  }
}

async function createCustomerProfileBusinessInformation({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  friendly_name,
  business_identity,
  business_industry,
  business_name,
  business_regions_of_operation,
  business_registration_identifier,
  business_registration_number,
  business_type,
  social_media_profile_urls,
  website_url,
}) {
  try {
    const res = await axios({
      method: "post",
      url: "https://trusthub.twilio.com/v1/EndUsers",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        Type: "customer_profile_business_information",
        FriendlyName: `customer profile business information ${friendly_name}`,
        Attributes: JSON.stringify({
          business_identity,
          business_industry,
          business_name,
          business_regions_of_operation,
          business_registration_identifier,
          business_registration_number,
          business_type,
          social_media_profile_urls,
          website_url,
        }),
      }),
    });

    return res.data.sid;
  } catch (e) {
    throw new Error(
      `error during api call createCustomerProfileBusinessInformation, ${e.message}`
    );
  }
}

async function createCustomerProfileAuthorizedRepresentative({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  friendly_name,
  business_title,
  email,
  first_name,
  last_name,
  job_position,
  phone_number,
}) {
  try {
    const res = await axios({
      method: "post",
      url: "https://trusthub.twilio.com/v1/EndUsers",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        Type: "authorized_representative_1",
        FriendlyName: `customer profile authorized representative ${friendly_name}`,
        Attributes: JSON.stringify({
          business_title,
          email,
          first_name,
          last_name,
          job_position,
          phone_number: `+1${phone_number}`,
        }),
      }),
    });

    return res.data.sid;
  } catch (e) {
    throw new Error(
      `error during api call createCustomerProfileBusinessInformation, ${e.message}`
    );
  }
}

async function createCustomerProfileAddress({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  friendly_name,
  street,
  city,
  region,
  iso_country,
  postal_code,
}) {
  try {
    const res = await axios({
      method: "post",
      url: `https://api.twilio.com/2010-04-01/Accounts/${TWILIO_ACCOUNT_SID}/Addresses.json`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        CustomerName: friendly_name,
        Street: street,
        City: city,
        Region: region,
        IsoCountry: iso_country,
        PostalCode: postal_code,
      }),
    });

    return res.data.sid;
  } catch (e) {
    throw new Error(
      `error during api call createCustomerProfileAddress, ${e.message}`
    );
  }
}

async function createCustomerDocument({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  friendly_name,
  addressSID,
}) {
  try {
    const res = await axios({
      method: "post",
      url: "https://trusthub.twilio.com/v1/SupportingDocuments",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        FriendlyName: `customer profile address ${friendly_name}`,
        Type: "customer_profile_address",
        Attributes: JSON.stringify({
          address_sids: addressSID,
        }),
      }),
    });

    return res.data.sid;
  } catch (e) {
    throw new Error(
      `error during api call createCustomerDocument, ${e.message}`
    );
  }
}

async function assignEntityToCustomerProfile({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  secondaryCustomerProfileSID,
  entitySID,
}) {
  try {
    await axios({
      method: "post",
      url: `https://trusthub.twilio.com/v1/CustomerProfiles/${secondaryCustomerProfileSID}/EntityAssignments`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        ObjectSid: entitySID,
      }),
    });
  } catch (e) {
    throw new Error(
      `error during api call assignEntityToCustomerProfile, ${e.message}`
    );
  }
}

async function evaluateSecondaryCustomerProfile({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  secondaryCustomerProfileSID,
}) {
  try {
    const res = await axios({
      method: "post",
      url: `https://trusthub.twilio.com/v1/CustomerProfiles/${secondaryCustomerProfileSID}/Evaluations`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        PolicySid: SECONDARY_CUSTOMER_PROFILE_POLICY_SID,
      }),
    });

    return { status: res.data.status, results: res.data.results };
  } catch (e) {
    throw new Error(
      `error during api call evaluateSecondaryCustomerProfile, ${e.message}`
    );
  }
}

async function submitReviewSecondaryCustomerProfile({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  secondaryCustomerProfileSID,
}) {
  try {
    await axios({
      method: "post",
      url: `https://trusthub.twilio.com/v1/CustomerProfiles/${secondaryCustomerProfileSID}`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        Status: "pending-review",
      }),
    });
  } catch (e) {
    throw new Error(
      `error during api call submitReviewSecondaryCustomerProfile, ${e.message}`
    );
  }
}

async function createA2PTrustProduct({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  friendly_name,
  email,
}) {
  try {
    const res = await axios({
      method: "post",
      url: "https://trusthub.twilio.com/v1/TrustProducts",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        FriendlyName: friendly_name,
        Email: email,
        PolicySid: A2P_TRUST_PRODUCT_POLICY_SID,
      }),
    });

    return res.data.sid;
  } catch (e) {
    throw new Error(
      `error during api call createA2PTrustProduct, ${e.message}`
    );
  }
}

async function createA2PMessagingProfileInformation({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  friendly_name,
  company_type,
}) {
  try {
    const res = await axios({
      method: "post",
      url: "https://trusthub.twilio.com/v1/EndUsers",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        FriendlyName: `A2P Information ${friendly_name}`,
        Type: "us_a2p_messaging_profile_information",
        Attributes: JSON.stringify({ company_type }),
      }),
    });

    return res.data.sid;
  } catch (e) {
    throw new Error(
      `error during api call createA2PMessagingProfileInformation, ${e.message}`
    );
  }
}

async function assignEntityToTrustProduct({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  trustProductSID,
  entitySID,
}) {
  try {
    await axios({
      method: "post",
      url: `https://trusthub.twilio.com/v1/TrustProducts/${trustProductSID}/EntityAssignments`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        ObjectSid: entitySID,
      }),
    });
  } catch (e) {
    throw new Error(
      `error during api call assignEntityToTrustProduct, ${e.message}`
    );
  }
}

async function evaluateTrustProduct({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  trustProductSID,
}) {
  try {
    const res = await axios({
      method: "post",
      url: `https://trusthub.twilio.com/v1/TrustProducts/${trustProductSID}/Evaluations`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        PolicySid: A2P_TRUST_PRODUCT_POLICY_SID,
      }),
    });

    return { status: res.data.status, results: res.data.results };
  } catch (e) {
    throw new Error(`error during api call evaluateTrustProduct, ${e.message}`);
  }
}

async function submitReviewTrustProduct({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  trustProductSID,
}) {
  try {
    await axios({
      method: "post",
      url: `https://trusthub.twilio.com/v1/TrustProducts/${trustProductSID}`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        Status: "pending-review",
      }),
    });
  } catch (e) {
    throw new Error(
      `error during api call submitReviewTrustProduct, ${e.message}`
    );
  }
}

async function createA2PBrand({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  secondaryCustomerProfileSID,
  trustProductSID,
}) {
  try {
    // limit API requests for Brand and Campaign registration to 1 req per sec
    while (new Date().getTime() - lastAPICallTimestamp < 1000) {
      await new Promise((res) => setTimeout(res, 2000));
    }
    lastAPICallTimestamp = new Date().getTime();

    const res = await axios({
      method: "post",
      url: "https://messaging.twilio.com/v1/a2p/BrandRegistrations",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        SkipAutomaticSecVet: "True",
        CustomerProfileBundleSid: secondaryCustomerProfileSID,
        A2PProfileBundleSid: trustProductSID,
      }),
    });

    return res.data.sid;
  } catch (e) {
    throw new Error(`error during api call createA2PBrand, ${e.message}`);
  }
}

async function getBrandRegistrationStatus({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  brandSID,
}) {
  try {
    // limit API requests for Brand and Campaign registration to 1 req per sec
    while (new Date().getTime() - lastAPICallTimestamp < 1000) {
      await new Promise((res) => setTimeout(res, 2000));
    }
    lastAPICallTimestamp = new Date().getTime();

    const res = await axios({
      method: "get",
      url: `https://messaging.twilio.com/v1/a2p/BrandRegistrations/${brandSID}`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
    });

    return { status: res.data.status, failure_reason: res.data.failure_reason };
  } catch (e) {
    throw new Error(
      `error during api call getBrandRegistrationStatus, ${e.message}`
    );
  }
}

async function createMessagingService({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  friendly_name,
}) {
  try {
    // limit API requests for Brand and Campaign registration to 1 req per sec
    while (new Date().getTime() - lastAPICallTimestamp < 1000) {
      await new Promise((res) => setTimeout(res, 2000));
    }
    lastAPICallTimestamp = new Date().getTime();

    const res = await axios({
      method: "post",
      url: "https://messaging.twilio.com/v1/Services",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        FriendlyName: `Messaging service ${friendly_name}`,
        UseInboundWebhookOnNumber: "True",
      }),
    });

    return res.data.sid;
  } catch (e) {
    throw new Error(
      `error during api call createMessagingService, ${e.message}`
    );
  }
}

async function createA2PCampaign({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  messagingServiceSID,
  brandSID,
  friendly_name,
  campaign_website_url,
}) {
  try {
    // limit API requests for Brand and Campaign registration to 1 req per sec
    while (new Date().getTime() - lastAPICallTimestamp < 1000) {
      await new Promise((res) => setTimeout(res, 2000));
    }
    lastAPICallTimestamp = new Date().getTime();

    const res = await axios({
      method: "post",
      url: `https://messaging.twilio.com/v1/Services/${messagingServiceSID}/Compliance/Usa2p`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify(
        {
          OptInKeywords: ["START", "SUBSCRIBE"],
          OptInMessage: `Thanks for choosing ${friendly_name}, and welcome to our family! This number is used for important information regarding your vehicle. Message and data rates may apply. Reply with STOP at any time to unsubscribe from SMS notifications. Thank you.`,
          OptOutKeywords: ["STOP", "CANCEL", "UNSUBSCRIBE"],
          Description:
            "This campaign is used for service reminders, follow ups, notifications, P2P and promotions for an auto repair shop to communicate with customers.",
          MessageFlow: `This business is required to use 1 of 3 methods of consent. 1) Written consent is provided within the payment process. The customer provides consent while making payment by selecting that they wish to receive SMS notifications, promotions, and reminders for services relating to their vehicle. 2) When a customer schedules an appointment online, they have the option to opt-in to messaging by input their mobile number. A disclaimer is included in the registration with who is sending messages, what they are sending, how to opt out, and that message and data rates may apply${
            campaign_website_url ? " (see link below)" : ""
          }. 3) The business receives verbal consent from the customer that they wish to receive SMS notifications, promotions, and reminders for their vehicle. All customers are required to provide consent. If a customer does not provide consent, they are required to be opted out of all SMS communication by the business.${
            campaign_website_url ? " " + campaign_website_url : ""
          }`,
          MessageSamples: [
            "You have an appointment tomorrow at 9 AM. STOP to unsubscribe",
            "Hello! Your vehicle is due for service this month. Give us a call or reply here to schedule. STOP to unsubscribe",
          ],
          UsAppToPersonUsecase: "LOW_VOLUME",
          HasEmbeddedLinks: "False",
          HasEmbeddedPhone: "False",
          BrandRegistrationSid: brandSID,
        },
        { arrayFormat: "repeat" }
      ),
    });

    return res.data.sid;
  } catch (e) {
    throw new Error(`error during api call createA2PCampaign, ${e.message}`);
  }
}

async function getPhoneNumbers({ TWILIO_ACCOUNT_SID, TWILIO_AUTH_TOKEN }) {
  try {
    let url = `/2010-04-01/Accounts/${TWILIO_ACCOUNT_SID}/IncomingPhoneNumbers.json?PageSize=20`;
    const phoneNumbers = [];

    while (url) {
      const res = await axios({
        method: "get",
        baseURL: "https://api.twilio.com",
        url,
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        auth: {
          username: TWILIO_ACCOUNT_SID,
          password: TWILIO_AUTH_TOKEN,
        },
        validateStatus: (status) => status < 500,
      });

      // when authorization is done using API key pair instead of account sid - response is 401 or 404 without incoming_phone_numbers
      if (!res.data.incoming_phone_numbers) return [];

      const receivedPhoneNumbers = res.data.incoming_phone_numbers.reduce(
        (acc, number) => {
          if (!number.capabilities.sms) return acc;
          acc.push({
            sid: number.sid,
            name: number.friendly_name,
            number: number.phone_number,
          });
          return acc;
        },
        []
      );

      phoneNumbers.push(...receivedPhoneNumbers);
      url = res.data.next_page_uri;
    }

    return phoneNumbers;
  } catch (e) {
    throw new Error(`error during api call getPhoneNumbers, ${e.message}`);
  }
}

async function addPhoneNumberToMessagingService({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  phoneNumberSid,
  messagingServiceSid,
}) {
  try {
    const res = await axios({
      method: "post",
      url: `https://messaging.twilio.com/v1/Services/${messagingServiceSid}/PhoneNumbers`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        PhoneNumberSid: phoneNumberSid,
      }),
    });

    return res.data.phone_number;
  } catch (e) {
    throw new Error(
      `error during api call addPhoneNumberToMessagingService, ${e.message}`
    );
  }
}
