import { useEffect, useRef, useState } from "react";
import styles from "./AppImport.module.scss";
import {
  importRowToTwilio,
  validateAndPrepareRowProperties,
  assignPhoneNumberToMessagingService,
} from "../../services/twilio.service";
import Button from "../Button/Button";
import cn from "classnames";
import Papa from "papaparse";

function AppImport() {
  const [fileData, setFileData] = useState();
  const [rows, setRows] = useState(null);
  const [allowMultipleSecondaryProfiles, setAllowMultipleSecondaryProfiles] =
    useState(false);
  const inputRef = useRef(null);
  const readyStatus = "ready to import";

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const matchRowsAndHeaders = (rows, headers) => {
    const dataRows = rows.map((data) => {
      const rowsColsMatch = {};
      data.map((item, i) => {
        return (rowsColsMatch[headers[i]] = item);
      });
      return rowsColsMatch;
    });

    return dataRows;
  };

  useEffect(() => {
    if (fileData) {
      const headers = fileData[0];
      const [, ...rowsData] = fileData;
      const matchedData = matchRowsAndHeaders(rowsData, headers);
      const validatedRows = matchedData.map((row) =>
        validateAndPrepareRowProperties(row)
      );

      const rowsState = validatedRows.reduce(
        (obj, item) =>
          Object.assign(obj, {
            [item.id]: {
              ...item,
              ...(!item.error && { status: readyStatus }),
            },
          }),
        {}
      );

      setRows(rowsState);
    }
  }, [fileData]);

  const handleFileChange = (e) => {
    if (!e.target.files) {
      return;
    }
    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      Papa.parse(text, {
        skipEmptyLines: true,
        complete: (res) => setFileData(res.data),
      });
    };

    reader.readAsText(e.target.files[0]);
  };

  const handleRowImportUpdate = ({ id, status, error, phoneNumbers }) => {
    setRows((prevState) => ({
      ...prevState,
      [id]: {
        ...prevState[id],
        status: status,
        ...(error && { error: error }),
        phoneNumbers,
      },
    }));
  };

  const handleImportData = () => {
    if (rows) {
      const rowsToImport = Object.entries(rows)
        .map((row) => row[1])
        .filter((row) => row.status === readyStatus);

      rowsToImport.forEach((row) => {
        importRowToTwilio(row.id, row.properties, handleRowImportUpdate, {
          allowMultipleSecondaryProfiles,
        });
      });
    }
  };

  const handlePhoneNumberAssignment = (numberSid, row) =>
    assignPhoneNumberToMessagingService(
      row.id,
      row.properties,
      handleRowImportUpdate,
      numberSid
    );

  return (
    <div>
      <div className={styles.buttonsWrapper}>
        <div>
          <Button onClick={() => handleUploadClick} title="Upload File" />
          <input
            type="file"
            ref={inputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
        </div>
        <Button onClick={() => handleImportData} title="Import Data" />
      </div>
      <div className={styles.buttonsWrapper}>
        <div
          className={styles.checkbox}
          onClick={() =>
            setAllowMultipleSecondaryProfiles(!allowMultipleSecondaryProfiles)
          }
        >
          <input
            type="checkbox"
            readOnly
            checked={allowMultipleSecondaryProfiles}
          />
          <span>
            Allow multiple Secondary Customer Profiles with different friendly
            names
          </span>
        </div>
      </div>
      {rows && (
        <div className={styles.rowsWrapper}>
          <div></div>
          {Object.entries(rows)
            .map((row) => row[1])
            .map((row) => (
              <Row
                error={row.error}
                shopName={row.properties.friendly_name}
                status={row.status}
                key={row.id}
                id={row.id}
                phoneNumbers={row.phoneNumbers}
                onNumberAssign={(sid) => handlePhoneNumberAssignment(sid, row)}
              />
            ))}
        </div>
      )}
    </div>
  );
}

export default AppImport;

function Row({ error, shopName, status, phoneNumbers, onNumberAssign }) {
  const [selectedNumberSid, setSelectedNumberSid] = useState("");
  const [isAssigningNumber, setIsAssigningNumber] = useState(false);
  return (
    <div className={cn(styles.row, { [styles.error]: error })}>
      <div className={styles.name}>{shopName}</div>
      <div className={styles.status}>{error || status}</div>
      {phoneNumbers && (
        <>
          <select
            disabled={isAssigningNumber}
            value={selectedNumberSid}
            onChange={(e) => setSelectedNumberSid(e.target.value)}
          >
            <option value={""}>select number</option>
            {phoneNumbers
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((i) => (
                <option
                  key={i.sid}
                  value={i.sid}
                >{`name:${i.name} number:${i.number}`}</option>
              ))}
          </select>
          <button
            disabled={isAssigningNumber || !selectedNumberSid}
            onClick={() => {
              setIsAssigningNumber(true);
              onNumberAssign(selectedNumberSid);
            }}
          >
            {isAssigningNumber ? "Assigning Number..." : "Assign This Number"}
          </button>
        </>
      )}
    </div>
  );
}
