import { useState } from "react";
import Papa from "papaparse";
import styles from "./AppReport.module.scss";
import Button from "../Button/Button";
import {
  generateReport,
  createCampaignsForReport,
  addPhoneNumberStatusesToReport,
  updateHubSpotForReport,
  generateCSVString,
} from "../../services/report.service";

function AppReport() {
  const [TWILIO_ACCOUNT_SID, setTWILIO_ACCOUNT_SID] = useState("");
  const [TWILIO_AUTH_TOKEN, setTWILIO_AUTH_TOKEN] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState(false);
  // const [HS_API_TOKEN, setHS_API_TOKEN] = useState("");
  const [brands, setBrands] = useState(false);
  const [isSkipCreateCampaigns, setIsSkipCreateCampaigns] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [output, setOutput] = useState([]);
  const [reportURL, setReportURL] = useState(null);
  const [filename, setFilename] = useState("");

  const onAddOutput = (el) => setOutput((x) => [...x, el]);
  const onUpdateLastOutput = (el) =>
    setOutput((x) => [...x.slice(0, x.length - 1), el]);

  const handlePhoneNumbersFileChange = (e) => {
    if (!e.target.files[0]) {
      setPhoneNumbers(false);
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      Papa.parse(text, {
        skipEmptyLines: true,
        header: true,
        complete: (res) => {
          const map = {};
          res.data.forEach((row) => (map[row.PHONENUMBERSID] = row.STATUS));
          setPhoneNumbers(map);
        },
      });
    };

    reader.readAsText(e.target.files[0]);
  };
  const handleBrandsFileChange = (e) => {
    if (!e.target.files[0]) {
      setBrands(false);
      return;
    }

    const reader = new FileReader();

    reader.onload = function (e) {
      const text = e.target.result;
      Papa.parse(text, {
        skipEmptyLines: true,
        header: true,
        complete: (res) => {
          const list = [];
          const brandStatusMap = {
            REGISTERED: "APPROVED",
          };
          res.data.forEach((row) => {
            list.push({
              //?
              //? unexplained
              //? Object.keys(row)[0] > "brandRegistrationSid"
              //? row['"brandRegistrationSid"'] > undefined
              //?
              sid: row[Object.keys(row)[0]],
              name: row.brandName,
              status: brandStatusMap[row.brandStatus] || "FAILED",
              brand_type: row.brandType,
            });
          });
          setBrands(list);
        },
      });
    };

    reader.readAsText(e.target.files[0]);
  };

  const handleRunReport = async () => {
    if (!TWILIO_ACCOUNT_SID || !TWILIO_AUTH_TOKEN) {
      setOutput(["cannot start, twilio tokens required"]);
      return;
    }
    setIsRunning(true);
    setReportURL(null);
    setOutput(["Started"]);

    const report = await generateReport({
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      brands, // generate report for main account
      onAddOutput,
      onUpdateLastOutput,
    });
    if (!brands && !isSkipCreateCampaigns) {
      await createCampaignsForReport({
        report,
        onAddOutput,
        onUpdateLastOutput,
      });
    }
    if (phoneNumbers) {
      await addPhoneNumberStatusesToReport({ report, phoneNumbers });
      // if (HS_API_TOKEN && !brands) {
      //   await updateHubSpotForReport({
      //     report,
      //     HS_API_TOKEN,
      //     onAddOutput,
      //     onUpdateLastOutput,
      //   });
      // }
    }
    const CSVString = generateCSVString({ report, onAddOutput });
    const blob = new Blob([CSVString], { type: "text/csv;charset=utf-8," });
    const objUrl = URL.createObjectURL(blob);
    setReportURL(objUrl);
    setFilename(
      `twilio-brands-status-${
        brands ? "main-account" : "subaccounts"
      }-${new Date().toLocaleDateString("en-US")}.csv`
    );
    setIsRunning(false);
    onAddOutput("Finished");
  };

  return (
    <div className={styles.AppReport}>
      <div className={styles.buttonsWrapper}>
        {reportURL && (
          <a className={styles.button} href={reportURL} download={filename}>
            Download report
          </a>
        )}
        <Button
          isDisabled={isRunning}
          onClick={() => handleRunReport}
          title="Run report"
        />
      </div>
      <div className={styles.input}>
        TWILIO_ACCOUNT_SID
        <input
          value={TWILIO_ACCOUNT_SID}
          onChange={(e) => setTWILIO_ACCOUNT_SID(e.target.value)}
        ></input>
      </div>
      <div className={styles.input}>
        TWILIO_AUTH_TOKEN
        <input
          type={isRunning || reportURL ? "password" : "text"}
          value={TWILIO_AUTH_TOKEN}
          onChange={(e) => setTWILIO_AUTH_TOKEN(e.target.value)}
        ></input>
      </div>
      <div className={styles.optional}>optional:</div>
      <div className={styles.item}>
        <div>1 add phone number statuses to report</div>
        <div>
          go to{" "}
          <a
            href="https://console.twilio.com/us1/develop/phone-numbers/manage/incoming/a2p-compliance/status"
            target="_blank"
            rel="noreferrer"
          >
            twilio
          </a>{" "}
          and generate new report, include numbers for all sub accounts and
          numbers already in compliance
        </div>
        <div>provide generated twilio numbers report</div>
        <input type="file" onChange={handlePhoneNumbersFileChange} />
      </div>
      {/* <div className={styles.item}>
        2 update HubSpot(option 1 is required)
        <div className={styles.input}>
          HS_API_TOKEN
          <input
            type={isRunning || reportURL ? "password" : "text"}
            value={HS_API_TOKEN}
            onChange={(e) => setHS_API_TOKEN(e.target.value)}
          ></input>
        </div>
      </div> */}
      <div className={styles.item}>
        <div>2 main account</div>
        <div>
          go to{" "}
          <a
            href="https://console.twilio.com/us1/develop/sms/regulatory-compliance/brands"
            target="_blank"
            rel="noreferrer"
          >
            twilio
          </a>{" "}
          and export CSV with all main account brands
        </div>
        <div>
          campaigns are not created for main account brands (option 3 is
          ignored)
        </div>
        <div>provide generated brands export CSV</div>
        <input type="file" onChange={handleBrandsFileChange} />
      </div>
      <div className={styles.item}>
        3{" "}
        <div
          className={styles.checkbox}
          onClick={() => setIsSkipCreateCampaigns((x) => !x)}
        >
          <span>skip missing campaigns creation</span>
          <input type="checkbox" readOnly checked={isSkipCreateCampaigns} />
        </div>
      </div>

      <div className={styles.output}>
        {output.map((line, i) => (
          <div key={i}>{line}</div>
        ))}
      </div>
    </div>
  );
}

export default AppReport;
