import { useState } from "react";
import cn from "classnames";
import styles from "./App.module.scss";
import AppImport from "./components/AppImport/AppImport";
import AppReport from "./components/AppReport/AppReport";

const apps = {
  AppImport: <AppImport />,
  AppReport: <AppReport />,
};

function App() {
  const [app, setApp] = useState("AppImport");

  return (
    <div>
      <div className={styles.tabs}>
        <div
          className={cn(styles.tab, {
            [styles.active]: app === "AppImport",
          })}
          onClick={() => setApp("AppImport")}
        >
          Import
        </div>
        <div
          className={cn(styles.tab, {
            [styles.active]: app === "AppReport",
          })}
          onClick={() => setApp("AppReport")}
        >
          Report
        </div>
      </div>
      {apps[app]}
    </div>
  );
}

export default App;
