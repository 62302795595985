import axios from "axios";
import axiosRetry from "axios-retry";
import qs from "qs";

let HS_API_TOKEN, TWILIO_ACCOUNT_SID, TWILIO_AUTH_TOKEN;

const hubSpotConfig = {
  objectType: {
    company: "0-2",
    brand: "2-20510937",
    campaign: "2-20511052",
    rooftop: "2-15543916",
  },
  associationType: {
    companyBrand: 74,
    brandCompany: 73,
    companyCampaign: 70,
    campaignCompany: 69,
    brandCampaign: 72,
    campaignBrand: 71,
  },
};

axiosRetry(axios, {
  retries: 9,
  retryDelay: (retryCount) => retryCount * 3000,
  onRetry: (retryCount, error) => {
    console.log(`retry #${retryCount} reason ${error.message}`);
  },
});

export async function generateReport({
  TWILIO_ACCOUNT_SID: inputSID,
  TWILIO_AUTH_TOKEN: inputTOKEN,
  brands,
  onAddOutput,
  onUpdateLastOutput,
}) {
  const start = Date.now();

  TWILIO_ACCOUNT_SID = inputSID;
  TWILIO_AUTH_TOKEN = inputTOKEN;

  const allAccountReports = await getAllAccountReports({
    TWILIO_ACCOUNT_SID,
    TWILIO_AUTH_TOKEN,
    brands,
    onAddOutput,
    onUpdateLastOutput,
  });

  const report = allAccountReports.reduce((acc, accountReport) => {
    acc[accountReport.sid] = accountReport;
    return acc;
  }, {});

  const finish = Date.now();

  onAddOutput(
    `Report generated in ${((finish - start) / 1000 / 60).toFixed(1)} min`
  );

  return report;
}

async function getAllAccountReports({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  brands,
  onAddOutput,
  onUpdateLastOutput,
}) {
  const accounts = await getAccounts({
    TWILIO_ACCOUNT_SID,
    TWILIO_AUTH_TOKEN,
    brands,
    onAddOutput,
    onUpdateLastOutput,
  });

  const allAccountReports = [];

  onAddOutput("");
  for (let account of accounts) {
    onUpdateLastOutput(
      `Progress(accounts):${allAccountReports.length + 1}/${accounts.length}`
    );

    const report = await getAccountReport({
      ...account,
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
      brands,
      onAddOutput,
      onUpdateLastOutput,
    });

    allAccountReports.push(report);
  }

  return allAccountReports;
}
async function getAccounts({
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  brands,
  onAddOutput,
  onUpdateLastOutput,
}) {
  try {
    onAddOutput("Getting accounts list");
    let URI = `/2010-04-01/Accounts.json?PageSize=100&Page=0`;

    const accounts = [];

    while (URI) {
      const res = await axios({
        method: "get",
        baseURL: "https://api.twilio.com",
        url: URI,
        auth: {
          username: TWILIO_ACCOUNT_SID,
          password: TWILIO_AUTH_TOKEN,
        },
      });

      const { accounts: receivedAccounts, next_page_uri } = res.data;

      receivedAccounts.forEach(({ owner_account_sid, sid, friendly_name }) => {
        const isMainAccount = sid === owner_account_sid;
        if ((isMainAccount && brands) || (!isMainAccount && !brands)) {
          accounts.push({ sid, friendly_name });
        }
      });

      URI = next_page_uri;
    }
    onAddOutput(`Total accounts number:${accounts.length}`);
    if (brands) onAddOutput(`Account brands number:${brands.length}`);

    return accounts;
  } catch (e) {
    throw new Error(`Error during API call getAccounts, ${e.message}`);
  }
}
async function getAccountReport({
  sid,
  friendly_name,
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
  brands,
  onAddOutput,
  onUpdateLastOutput,
}) {
  const accountReport = { sid, friendly_name };

  const { sid: API_KEY_SID, secret: API_KEY_SECRET } =
    await getAccountAPIKeyPair({ sid, TWILIO_ACCOUNT_SID, TWILIO_AUTH_TOKEN });

  if (!API_KEY_SID) {
    accountReport.brands = {};
    return accountReport;
  }

  const brandsMap = await getAccountBrandsMap({
    API_KEY_SID,
    API_KEY_SECRET,
    brands,
  });

  const campaignsMapsByBrand = await getAccountCampaignsMapsByBrand({
    API_KEY_SID,
    API_KEY_SECRET,
  });

  // for subaccounts where one brand from all should have working campaign
  //* APPROVED brand without VERIFIED or IN_PROGRESS campaign
  let brandIdMissingCampaign = null;
  //* false if any of APPROVED brands has VERIFIED or IN_PROGRESS campaign
  let isMissingCampaign = true;
  //

  Object.entries(campaignsMapsByBrand).forEach(([brandId, campaignsMap]) => {
    // skip if campaigns found for not existing brand
    if (!brandsMap[brandId]) return;

    brandsMap[brandId].campaigns = campaignsMap;

    if (brandsMap[brandId].status === "APPROVED") {
      const hasValidCampaign = Object.values(campaignsMap).some(
        (campaign) =>
          campaign.status === "VERIFIED" || campaign.status === "IN_PROGRESS"
      );

      if (brands) {
        if (!hasValidCampaign) {
          brandsMap[brandId].isMissingCampaign = true;
          accountReport.API_KEY_SID = API_KEY_SID;
          accountReport.API_KEY_SECRET = API_KEY_SECRET;
        }
      } else {
        if (hasValidCampaign) {
          isMissingCampaign = false;
        } else {
          brandIdMissingCampaign = brandId;
        }
      }
    }
  });

  if (!brands) {
    if (isMissingCampaign) {
      if (!brandIdMissingCampaign) {
        const approvedBrandWithoutCampaigns = Object.values(brandsMap).find(
          (brand) =>
            !Object.keys(brand.campaigns).length && brand.status === "APPROVED"
        );
        if (approvedBrandWithoutCampaigns) {
          brandIdMissingCampaign = approvedBrandWithoutCampaigns.sid;
        }
      }
      if (brandIdMissingCampaign) {
        brandsMap[brandIdMissingCampaign].isMissingCampaign = true;
        accountReport.API_KEY_SID = API_KEY_SID;
        accountReport.API_KEY_SECRET = API_KEY_SECRET;
      }
    }
  } else {
    Object.values(brandsMap).forEach((brand) => {
      if (!Object.keys(brand.campaigns).length && brand.status === "APPROVED") {
        brand.isMissingCampaign = true;
        accountReport.API_KEY_SID = API_KEY_SID;
        accountReport.API_KEY_SECRET = API_KEY_SECRET;
      }
    });
  }

  accountReport.brands = brandsMap;

  return accountReport;
}

async function getAccountAPIKeyPair({
  sid,
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
}) {
  const existingKey = await getAccountAPIKey({
    sid,
    TWILIO_ACCOUNT_SID,
    TWILIO_AUTH_TOKEN,
  });

  if (existingKey) {
    await deleteAccountAPIKey({
      sid,
      keySid: existingKey.sid,
      TWILIO_ACCOUNT_SID,
      TWILIO_AUTH_TOKEN,
    });
  }

  const newKey = await createAccountAPIKey({
    sid,
    TWILIO_ACCOUNT_SID,
    TWILIO_AUTH_TOKEN,
  });

  return { sid: newKey.sid, secret: newKey.secret };
}
async function getAccountAPIKey({
  sid,
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
}) {
  try {
    let URI = `/2010-04-01/Accounts/${sid}/Keys.json`;

    let key;

    while (URI) {
      const res = await axios({
        method: "get",
        baseURL: "https://api.twilio.com",
        url: URI,
        auth: {
          username: TWILIO_ACCOUNT_SID,
          password: TWILIO_AUTH_TOKEN,
        },
      });

      const { keys, next_page_uri } = res.data;

      key = keys.find((el) => el.friendly_name === "10DLC REPORT");

      URI = next_page_uri;
    }

    return key || null;
  } catch (e) {
    throw new Error(`Error during API call getAccountAPIKey, ${e.message}`);
  }
}
async function createAccountAPIKey({
  sid,
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
}) {
  try {
    let URI = `/2010-04-01/Accounts/${sid}/Keys.json`;

    const res = await axios({
      method: "post",
      baseURL: "https://api.twilio.com",
      url: URI,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
      data: qs.stringify({
        FriendlyName: `10DLC REPORT`,
      }),
      validateStatus: (status) => status < 500,
    });

    if (res.status === 401) {
      console.log(`createAccountAPIKey response ${res.status}, sid:${sid}`);
      return {};
    }

    return res.data;
  } catch (e) {
    throw new Error(`Error during API call createAccountAPIKey, ${e.message}`);
  }
}
async function deleteAccountAPIKey({
  sid,
  keySid,
  TWILIO_ACCOUNT_SID,
  TWILIO_AUTH_TOKEN,
}) {
  try {
    let URI = `/2010-04-01/Accounts/${sid}/Keys/${keySid}.json`;

    await axios({
      method: "delete",
      baseURL: "https://api.twilio.com",
      url: URI,
      auth: {
        username: TWILIO_ACCOUNT_SID,
        password: TWILIO_AUTH_TOKEN,
      },
    });
  } catch (e) {
    throw new Error(`Error during API call deleteAccountAPIKey, ${e.message}`);
  }
}

async function getAccountBrandsMap({
  API_KEY_SID,
  API_KEY_SECRET,
  brands: brandsInput,
}) {
  const brands = brandsInput
    ? brandsInput
    : await getBrands({ API_KEY_SID, API_KEY_SECRET });

  const formattedBrands = await Promise.all(
    brands.map(async (brand) => {
      const {
        sid,
        status,
        failure_reason,
        a2p_profile_bundle_sid,
        brand_type,
        skip_automatic_sec_vet,
      } = brand;

      const type =
        brand_type === "STANDARD" && skip_automatic_sec_vet
          ? "LOW VOLUME STANDARD"
          : brand_type;

      const { friendly_name: brand_name } = brandsInput
        ? { friendly_name: brand.name }
        : await getTrustProduct({
            sid: a2p_profile_bundle_sid,
            API_KEY_SID,
            API_KEY_SECRET,
          });

      // Try to extract HubSpot ID from Brand Friendly Name
      const match = brand_name?.match(/(?<name>.*) - HSC?(?<id>.*)$/);
      const friendly_name = match?.groups.name || brand_name;
      const hubspot_id = match?.groups.id;

      return {
        sid,
        status,
        failure_reason,
        friendly_name,
        hubspot_id,
        type,
        campaigns: {},
      };
    })
  );

  const brandsMap = formattedBrands.reduce((acc, brand) => {
    acc[brand.sid] = brand;
    return acc;
  }, {});

  return brandsMap;
}
async function getBrands({ API_KEY_SID, API_KEY_SECRET }) {
  try {
    let URL = `https://messaging.twilio.com/v1/a2p/BrandRegistrations?PageSize=50&Page=0`;

    const brands = [];

    while (URL) {
      const res = await axios({
        method: "get",
        url: URL,
        auth: {
          username: API_KEY_SID,
          password: API_KEY_SECRET,
        },
      });

      const {
        data: receivedBrands,
        meta: { next_page_url },
      } = res.data;

      brands.push(...receivedBrands);
      URL = next_page_url;
    }

    return brands;
  } catch (e) {
    throw new Error(`Error during API call getAccountBrands, ${e.message}`);
  }
}
async function getTrustProduct({ sid, API_KEY_SID, API_KEY_SECRET }) {
  try {
    const res = await axios({
      method: "get",
      url: `https://trusthub.twilio.com/v1/TrustProducts/${sid}`,
      auth: {
        username: API_KEY_SID,
        password: API_KEY_SECRET,
      },
      validateStatus: (status) => status < 500,
    });

    if (res.status === 404) {
      // work around for shared brands, where trust product is not accessible with subaccount credentials

      const res2 = await axios({
        method: "get",
        url: `https://trusthub.twilio.com/v1/TrustProducts/${sid}`,
        auth: {
          username: TWILIO_ACCOUNT_SID,
          password: TWILIO_AUTH_TOKEN,
        },
        validateStatus: (status) => status < 500,
      });

      if (res2.status === 200) return res2.data;
    }

    if (res.status !== 200) {
      console.log(`getTrustProduct response ${res.status}, sid:${sid}`);
      return {};
    }

    return res.data;
  } catch (e) {
    throw new Error(`Error during API call getTrustProduct, ${e.message}`);
  }
}
async function getAccountCampaignsMapsByBrand({ API_KEY_SID, API_KEY_SECRET }) {
  const messagingServices = await getMessagingServices({
    API_KEY_SID,
    API_KEY_SECRET,
  });

  // fetch messaging services data one by one to not overload network/twilio
  const servicesCampaignsAndPhoneNumbers = [];
  for (let { sid } of messagingServices) {
    const serviceData = await Promise.all([
      getServiceCampaigns({ sid, API_KEY_SID, API_KEY_SECRET }),
      getServicePhoneNumbers({ sid, API_KEY_SID, API_KEY_SECRET }),
    ]);
    servicesCampaignsAndPhoneNumbers.push(serviceData);
  }

  const campaignsMapsByBrand = servicesCampaignsAndPhoneNumbers.reduce(
    (acc, [campaigns, phoneNumbers]) => {
      const phoneNumbersMap = phoneNumbers.reduce(
        (acc, { sid, phone_number }) => {
          acc[sid] = { sid, phoneNumber: phone_number };
          return acc;
        },
        {}
      );

      // campaign sid is not available at API, using messaging service sid instead, can be many campaigns within one service
      const numberOfMessagingServices = {};

      campaigns.forEach(
        ({
          messaging_service_sid,
          campaign_status,
          campaign_id,
          brand_registration_sid,
          date_created,
          date_updated,
        }) => {
          if (!acc[brand_registration_sid]) acc[brand_registration_sid] = {};

          let updated_messaging_service_sid = messaging_service_sid;
          if (!numberOfMessagingServices[messaging_service_sid]) {
            numberOfMessagingServices[messaging_service_sid] = 1;
          } else {
            numberOfMessagingServices[messaging_service_sid] =
              numberOfMessagingServices[messaging_service_sid] + 1;
            updated_messaging_service_sid =
              numberOfMessagingServices[messaging_service_sid] +
              messaging_service_sid;
          }

          acc[brand_registration_sid][updated_messaging_service_sid] = {
            sid: messaging_service_sid,
            status: campaign_status,
            id: campaign_id,
            phoneNumbers: phoneNumbersMap,
            dateCreated: date_created,
            dateUpdated: date_updated,
          };
        }
      );

      return acc;
    },
    {}
  );

  return campaignsMapsByBrand;
}
async function getMessagingServices({ API_KEY_SID, API_KEY_SECRET }) {
  try {
    let URL = `https://messaging.twilio.com/v1/Services?PageSize=50&Page=0`;

    const services = [];

    while (URL) {
      const res = await axios({
        method: "get",
        url: URL,
        auth: {
          username: API_KEY_SID,
          password: API_KEY_SECRET,
        },
      });

      const {
        services: receivedServices,
        meta: { next_page_url },
      } = res.data;

      services.push(...receivedServices);
      URL = next_page_url;
    }

    return services;
  } catch (e) {
    throw new Error(`Error during API call getMessagingServices, ${e.message}`);
  }
}
async function getServiceCampaigns({ sid, API_KEY_SID, API_KEY_SECRET }) {
  try {
    let URL = `https://messaging.twilio.com/v1/Services/${sid}/Compliance/Usa2p?PageSize=50&Page=0`;

    const campaigns = [];

    while (URL) {
      const res = await axios({
        method: "get",
        url: URL,
        auth: {
          username: API_KEY_SID,
          password: API_KEY_SECRET,
        },
      });

      const {
        compliance: receivedCampaigns,
        meta: { next_page_url },
      } = res.data;

      campaigns.push(...receivedCampaigns);
      URL = next_page_url;
    }

    return campaigns;
  } catch (e) {
    throw new Error(`Error during API call getServiceCampaigns, ${e.message}`);
  }
}
async function getServicePhoneNumbers({ sid, API_KEY_SID, API_KEY_SECRET }) {
  try {
    let URL = `https://messaging.twilio.com/v1/Services/${sid}/PhoneNumbers?PageSize=50&Page=0`;

    const phoneNumbers = [];

    while (URL) {
      const res = await axios({
        method: "get",
        url: URL,
        auth: {
          username: API_KEY_SID,
          password: API_KEY_SECRET,
        },
      });

      const {
        phone_numbers: receivedPhoneNumbers,
        meta: { next_page_url },
      } = res.data;

      phoneNumbers.push(...receivedPhoneNumbers);
      URL = next_page_url;
    }

    return phoneNumbers;
  } catch (e) {
    throw new Error(
      `Error during API call getServicePhoneNumbers, ${e.message}`
    );
  }
}

export async function createCampaignsForReport({
  report,
  onAddOutput,
  onUpdateLastOutput,
}) {
  onAddOutput("Verifying for missing campaigns...");

  let newCampaignsCount = 0;
  let failedCampaignsCount = 0;
  let creatingMessageShown = false;

  for (const account of Object.values(report)) {
    const { API_KEY_SID, API_KEY_SECRET, brands } = account;

    for (const brand of Object.values(brands)) {
      if (brand.isMissingCampaign && brand.type === "LOW VOLUME STANDARD") {
        const { sid, friendly_name } = brand;

        // sometimes friendly name is missing, but its required for campaign
        if (!friendly_name) {
          failedCampaignsCount++;
          continue;
        }

        if (!creatingMessageShown) {
          onAddOutput("Creating missing campaigns...");
          creatingMessageShown = true;
        }

        try {
          const messagingServiceSID = await createMessagingService({
            API_KEY_SID,
            API_KEY_SECRET,
            friendly_name,
          });

          const campaign = await createA2PCampaign({
            API_KEY_SID,
            API_KEY_SECRET,
            messagingServiceSID,
            brandSID: sid,
            friendly_name,
          });

          const { campaign_status, campaign_id, date_created } = campaign;

          brand.campaigns[messagingServiceSID] = {
            sid: messagingServiceSID,
            status: campaign_status,
            id: campaign_id,
            phoneNumbers: {},
            dateCreated: date_created,
            isCreated: true,
          };

          newCampaignsCount++;
        } catch (e) {
          console.log(
            `Error during campaign creation for brand ${sid} ${friendly_name} ${e.message}`
          );
          failedCampaignsCount++;
        }
      }
    }
  }
  if (creatingMessageShown) {
    onAddOutput(`Created ${newCampaignsCount} missing campaigns`);
  }
  if (failedCampaignsCount) {
    onAddOutput(`Failed to create ${failedCampaignsCount} campaigns`);
  }
}
async function createMessagingService({
  API_KEY_SID,
  API_KEY_SECRET,
  friendly_name,
}) {
  try {
    // limit API requests for Brand and Campaign registration to 1 req per sec
    await new Promise((res) => setTimeout(res, 1000));

    const res = await axios({
      method: "post",
      url: "https://messaging.twilio.com/v1/Services",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: API_KEY_SID,
        password: API_KEY_SECRET,
      },
      data: qs.stringify({
        FriendlyName: `Messaging service ${friendly_name}`,
        UseInboundWebhookOnNumber: "True",
      }),
    });

    return res.data.sid;
  } catch (e) {
    throw new Error(
      `error during api call createMessagingService, ${e.message}`
    );
  }
}
async function createA2PCampaign({
  API_KEY_SID,
  API_KEY_SECRET,
  messagingServiceSID,
  brandSID,
  friendly_name,
}) {
  try {
    // limit API requests for Brand and Campaign registration to 1 req per sec
    await new Promise((res) => setTimeout(res, 1000));

    const res = await axios({
      method: "post",
      url: `https://messaging.twilio.com/v1/Services/${messagingServiceSID}/Compliance/Usa2p`,
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      auth: {
        username: API_KEY_SID,
        password: API_KEY_SECRET,
      },
      data: qs.stringify(
        {
          OptInKeywords: ["START", "SUBSCRIBE"],
          OptInMessage: `Thanks for choosing ${friendly_name}, and welcome to our family! This number is used for important information regarding your vehicle. Message and data rates may apply. Reply with STOP at any time to unsubscribe from SMS notifications. Thank you.`,
          OptOutKeywords: ["STOP", "CANCEL", "UNSUBSCRIBE"],
          Description:
            "This campaign is used for service reminders, follow ups, notifications, P2P and promotions for an auto repair shop to communicate with customers.",
          MessageFlow:
            "This business is required to use 1 of 3 methods of consent. 1) Written consent is provided within the payment process. The customer provides consent while making payment by selecting that they wish to receive SMS notifications, promotions, and reminders for services relating to their vehicle. 2) When a customer schedules an appointment online, they have the option to opt-in to messaging by input their mobile number. A disclaimer is included in the registration with who is sending messages, what they are sending, how to opt out, and that message and data rates may apply. 3) The business receives verbal consent from the customer that they wish to receive SMS notifications, promotions, and reminders for their vehicle. All customers are required to provide consent. If a customer does not provide consent, they are required to be opted out of all SMS communication by the business.",
          MessageSamples: [
            "You have an appointment tomorrow at 9 AM",
            "Hello! Your vehicle is due for service this month. Give us a call or reply here to schedule",
          ],
          UsAppToPersonUsecase: "LOW_VOLUME",
          HasEmbeddedLinks: "False",
          HasEmbeddedPhone: "False",
          BrandRegistrationSid: brandSID,
        },
        { arrayFormat: "repeat" }
      ),
    });

    return res.data;
  } catch (e) {
    throw new Error(`error during api call createA2PCampaign, ${e.message}`);
  }
}

export async function addPhoneNumberStatusesToReport({
  report,
  phoneNumbers: phoneNumbersStatusBySIDMap,
}) {
  for (const account of Object.values(report)) {
    const { brands } = account;

    for (const brand of Object.values(brands)) {
      const { campaigns } = brand;

      for (const campaign of Object.values(campaigns)) {
        const { phoneNumbers } = campaign;

        for (const phoneNumber of Object.values(phoneNumbers)) {
          const { sid } = phoneNumber;

          phoneNumber.status = phoneNumbersStatusBySIDMap[sid];
        }
      }
    }
  }
}

export async function updateHubSpotForReport({
  report,
  HS_API_TOKEN: inputToken,
  onAddOutput,
  onUpdateLastOutput,
}) {
  HS_API_TOKEN = inputToken;
  onAddOutput("Updating HubSpot...");

  let countProcessedAccounts = 0;
  const countTotalAccounts = Object.keys(report).length;

  onAddOutput("");

  for (const account of Object.values(report)) {
    onUpdateLastOutput(
      `progress: ${++countProcessedAccounts}/${countTotalAccounts}... `
    );

    for (const brand of Object.values(account.brands)) {
      if (!brand.hubspot_id) continue;

      await reconcileHubSpotBrand({ brand });

      for (const campaign of Object.values(brand.campaigns)) {
        if (!campaign.id || !brand.hs_id) continue;

        await reconcileHubSpotCampaign({
          campaign,
          company_id: brand.hubspot_id,
          brand_id: brand.hs_id,
        });

        for (const number of Object.values(campaign.phoneNumbers)) {
          if (!number.phoneNumber) continue;

          await reconcileHubSpotRooftop({
            number,
            accountSID: account.sid,
            messagingServiceSID: campaign.sid,
          });
        }
      }
    }
  }
}
async function reconcileHubSpotBrand({ brand }) {
  try {
    const { hubspot_id: company_id, sid, status, failure_reason } = brand;

    const HSBrand = await getHubSpotBrand({ sid });

    if (HSBrand) {
      const { id, brand_status, brand_failure_reason } = HSBrand;

      const isStatusChanged = status !== brand_status;
      const isFailureReasonChanged =
        (failure_reason || brand_failure_reason) &&
        failure_reason !== brand_failure_reason;

      if (isStatusChanged || isFailureReasonChanged) {
        const properties = {
          brand_status: status,
          brand_failure_reason: failure_reason || "",
        };

        await updateHubSpotBrand({ id, properties });

        brand.hs_sync = "UPDATED";
      }

      brand.hs_id = id;
    } else {
      const { id } = await createHubSpotBrand({
        sid,
        status,
        failure_reason,
      });

      brand.hs_id = id;

      await Promise.all([
        associateHubSpotObjects({
          objectType: hubSpotConfig.objectType.brand,
          objectId: id,
          toObjectType: hubSpotConfig.objectType.company,
          toObjectId: company_id,
          associationType: hubSpotConfig.associationType.brandCompany,
        }),
        associateHubSpotObjects({
          objectType: hubSpotConfig.objectType.company,
          objectId: company_id,
          toObjectType: hubSpotConfig.objectType.brand,
          toObjectId: id,
          associationType: hubSpotConfig.associationType.companyBrand,
        }),
      ]);

      brand.hs_sync = "CREATED";
    }
  } catch (e) {
    brand.hs_sync = "ERROR";
  }
}
async function createHubSpotBrand({ sid, status, failure_reason }) {
  try {
    const res = await axios({
      method: "post",
      baseURL: "https://api.hubapi.com",
      url: `/crm/v3/objects/${hubSpotConfig.objectType.brand}`,
      headers: {
        Authorization: `Bearer ${HS_API_TOKEN}`,
        "Content-Type": "application/json",
      },
      validateStatus: (status) => status < 500,
      data: {
        properties: {
          brand_sid: sid,
          brand_status: status,
          brand_failure_reason: failure_reason,
        },
      },
    });

    if (res.status === 201) return res.data;

    throw new Error(`Error during API call createHubSpotBrand, ${res.status}`);
  } catch (e) {
    throw new Error(`Error during API call createHubSpotBrand, ${e.message}`);
  }
}
async function getHubSpotBrand({ sid }) {
  try {
    const res = await axios({
      method: "get",
      baseURL: "https://api.hubapi.com",
      url: `/crm/v3/objects/${hubSpotConfig.objectType.brand}/${sid}`,
      params: {
        idProperty: "brand_sid",
        properties: "brand_status,brand_failure_reason",
      },
      headers: {
        Authorization: `Bearer ${HS_API_TOKEN}`,
        "Content-Type": "application/json",
      },
      validateStatus: (status) => status < 500,
    });

    if (res.status === 200) {
      const {
        id,
        properties: { brand_status, brand_failure_reason },
      } = res.data;

      return { id, brand_status, brand_failure_reason };
    }

    if (res.status === 404) {
      return null;
    }

    throw new Error(`Error during API call getHubSpotBrand, ${res.status}`);
  } catch (e) {
    throw new Error(`Error during API call getHubSpotBrand, ${e.message}`);
  }
}
async function updateHubSpotBrand({ id, properties }) {
  try {
    const res = await axios({
      method: "patch",
      baseURL: "https://api.hubapi.com",
      url: `/crm/v3/objects/${hubSpotConfig.objectType.brand}/${id}`,
      headers: {
        Authorization: `Bearer ${HS_API_TOKEN}`,
        "Content-Type": "application/json",
      },
      validateStatus: (status) => status < 500,
      data: {
        properties,
      },
    });

    if (res.status === 200) return;

    throw new Error(`Error during API call updateHubSpotBrand, ${res.status}`);
  } catch (e) {
    throw new Error(`Error during API call updateHubSpotBrand, ${e.message}`);
  }
}
async function reconcileHubSpotCampaign({ campaign, company_id, brand_id }) {
  try {
    const { id: campaign_id, status } = campaign;

    const HSCampaign = await getHubSpotCampaign({ id: campaign_id });

    if (HSCampaign) {
      const { id, campaign_status } = HSCampaign;

      if (campaign_status !== status) {
        const properties = { campaign_status: status };

        await updateHubSpotCampaign({ id, properties });

        campaign.hs_sync = "UPDATED";
      }
      campaign.hs_id = id;
    } else {
      const { id } = await createHubSpotCampaign({ id: campaign_id, status });

      campaign.hs_id = id;

      await Promise.all([
        associateHubSpotObjects({
          objectType: hubSpotConfig.objectType.campaign,
          objectId: id,
          toObjectType: hubSpotConfig.objectType.company,
          toObjectId: company_id,
          associationType: hubSpotConfig.associationType.campaignCompany,
        }),
        associateHubSpotObjects({
          objectType: hubSpotConfig.objectType.company,
          objectId: company_id,
          toObjectType: hubSpotConfig.objectType.campaign,
          toObjectId: id,
          associationType: hubSpotConfig.associationType.companyCampaign,
        }),
        associateHubSpotObjects({
          objectType: hubSpotConfig.objectType.campaign,
          objectId: id,
          toObjectType: hubSpotConfig.objectType.brand,
          toObjectId: brand_id,
          associationType: hubSpotConfig.associationType.campaignBrand,
        }),
        associateHubSpotObjects({
          objectType: hubSpotConfig.objectType.brand,
          objectId: brand_id,
          toObjectType: hubSpotConfig.objectType.campaign,
          toObjectId: id,
          associationType: hubSpotConfig.associationType.brandCampaign,
        }),
      ]);

      campaign.hs_sync = "CREATED";
    }
  } catch (e) {
    campaign.hs_sync = "ERROR";
  }
}
async function createHubSpotCampaign({ id, status }) {
  try {
    const res = await axios({
      method: "post",
      baseURL: "https://api.hubapi.com",
      url: `/crm/v3/objects/${hubSpotConfig.objectType.campaign}`,
      headers: {
        Authorization: `Bearer ${HS_API_TOKEN}`,
        "Content-Type": "application/json",
      },
      validateStatus: (status) => status < 500,
      data: {
        properties: {
          campaign_id: id,
          campaign_status: status,
        },
      },
    });

    if (res.status === 201) return res.data;

    throw new Error(
      `Error during API call createHubSpotCampaign, ${res.status}`
    );
  } catch (e) {
    throw new Error(
      `Error during API call createHubSpotCampaign, ${e.message}`
    );
  }
}
async function getHubSpotCampaign({ id }) {
  try {
    const res = await axios({
      method: "get",
      baseURL: "https://api.hubapi.com",
      url: `/crm/v3/objects/${hubSpotConfig.objectType.campaign}/${id}`,
      params: {
        idProperty: "campaign_id",
        properties: "campaign_status",
      },
      headers: {
        Authorization: `Bearer ${HS_API_TOKEN}`,
        "Content-Type": "application/json",
      },
      validateStatus: (status) => status < 500,
    });

    if (res.status === 200) {
      const {
        id,
        properties: { campaign_status },
      } = res.data;

      return { id, campaign_status };
    }

    if (res.status === 404) {
      return null;
    }

    throw new Error(`Error during API call getHubSpotCampaign, ${res.status}`);
  } catch (e) {
    throw new Error(`Error during API call getHubSpotCampaign, ${e.message}`);
  }
}
async function updateHubSpotCampaign({ id, properties }) {
  try {
    const res = await axios({
      method: "patch",
      baseURL: "https://api.hubapi.com",
      url: `/crm/v3/objects/${hubSpotConfig.objectType.campaign}/${id}`,
      headers: {
        Authorization: `Bearer ${HS_API_TOKEN}`,
        "Content-Type": "application/json",
      },
      validateStatus: (status) => status < 500,
      data: {
        properties,
      },
    });

    if (res.status === 200) return;

    throw new Error(
      `Error during API call updateHubSpotCampaign, ${res.status}`
    );
  } catch (e) {
    throw new Error(
      `Error during API call updateHubSpotCampaign, ${e.message}`
    );
  }
}
async function reconcileHubSpotRooftop({
  number,
  accountSID,
  messagingServiceSID,
}) {
  try {
    const { phoneNumber, status } = number;
    const formattedPhoneNumber = phoneNumber.slice(-10);

    const HSRooftop = await getHubSpotRooftop({
      phoneNumber: formattedPhoneNumber,
    });

    if (HSRooftop) {
      const {
        id,
        steer_10dlc_phone_status,
        twilio_subaccount_sid,
        messaging_sid,
      } = HSRooftop;

      if (
        steer_10dlc_phone_status !== status ||
        twilio_subaccount_sid !== accountSID ||
        messaging_sid !== messagingServiceSID
      ) {
        const properties = {
          steer_10dlc_phone_status: status,
          twilio_subaccount_sid: accountSID,
          messaging_sid: messagingServiceSID,
        };

        await updateHubSpotRooftop({ id, properties });

        number.hs_sync = "UPDATED";
      }
      number.hs_id = id;
    } else {
      number.hs_sync = "ERROR";
    }
  } catch (e) {
    number.hs_sync = "ERROR";
  }
}
async function getHubSpotRooftop({ phoneNumber }) {
  try {
    const res = await axios({
      method: "get",
      baseURL: "https://api.hubapi.com",
      url: `/crm/v3/objects/${hubSpotConfig.objectType.rooftop}/${phoneNumber}`,
      params: {
        idProperty: "texting_number__twilio_integration_",
        properties:
          "steer_10dlc_phone_status,twilio_subaccount_sid,messaging_sid",
      },
      headers: {
        Authorization: `Bearer ${HS_API_TOKEN}`,
        "Content-Type": "application/json",
      },
      validateStatus: (status) => status < 500,
    });

    if (res.status === 200) {
      const {
        id,
        properties: {
          steer_10dlc_phone_status,
          twilio_subaccount_sid,
          messaging_sid,
        },
      } = res.data;

      return {
        id,
        steer_10dlc_phone_status,
        twilio_subaccount_sid,
        messaging_sid,
      };
    }

    if (res.status === 404) {
      return null;
    }

    throw new Error(`Error during API call getHubSpotRooftop, ${res.status}`);
  } catch (e) {
    throw new Error(`Error during API call getHubSpotRooftop, ${e.message}`);
  }
}
async function updateHubSpotRooftop({ id, properties }) {
  try {
    const res = await axios({
      method: "patch",
      baseURL: "https://api.hubapi.com",
      url: `/crm/v3/objects/${hubSpotConfig.objectType.rooftop}/${id}`,
      headers: {
        Authorization: `Bearer ${HS_API_TOKEN}`,
        "Content-Type": "application/json",
      },
      validateStatus: (status) => status < 500,
      data: {
        properties,
      },
    });

    if (res.status === 200) return;

    throw new Error(
      `Error during API call updateHubSpotRooftop, ${res.status}`
    );
  } catch (e) {
    throw new Error(`Error during API call updateHubSpotRooftop, ${e.message}`);
  }
}
async function associateHubSpotObjects({
  objectType,
  objectId,
  toObjectType,
  toObjectId,
  associationType,
}) {
  try {
    const res = await axios({
      method: "put",
      baseURL: "https://api.hubapi.com",
      url: `/crm/v3/objects/${objectType}/${objectId}/associations/${toObjectType}/${toObjectId}/${associationType}`,
      headers: {
        Authorization: `Bearer ${HS_API_TOKEN}`,
        "Content-Type": "application/json",
      },
      validateStatus: (status) => status < 500,
    });

    if (res.status === 200) return;

    throw new Error(
      `Error during API call associateHubSpotObjects, ${res.status}`
    );
  } catch (e) {
    throw new Error(
      `Error during API call associateHubSpotObjects, ${e.message}`
    );
  }
}

export function generateCSVString({ report, onAddOutput }) {
  onAddOutput("Preparing CSV file");

  const CSVRows = [];

  const rowTemplate = {
    "Account SID": "",
    "Account Name": "",
    "Brand SID": "",
    "Brand Name": "",
    "Brand Status": "",
    "Brand Failure Reason": "",
    "Brand Type": "",
    "Company HubSpot ID": "",
    "Brand HubSpot ID": "",
    "Brand HubSpot Sync": "",
    "Missing Campaign": "",
    "Campaign Created": "",
    "Messaging Service SID": "",
    "Campaign ID": "",
    "Campaign Status": "",
    "Campaign HubSpot ID": "",
    "Campaign HubSpot Sync": "",
    "Campaign Date Created": "",
    "Campaign Date Updated": "",
    "Phone Number SID": "",
    "Phone Number": "",
    "Phone Number Status": "",
    "Rooftop HubSpot ID": "",
    "Rooftop HubSpot Sync": "",
  };

  CSVRows.push('"' + Object.keys(rowTemplate).join('","') + '"\n');

  Object.entries(report).forEach(([accountSID, accountData]) => {
    const rowAccount = { ...rowTemplate };
    rowAccount["Account SID"] = accountSID;
    rowAccount["Account Name"] = accountData.friendly_name;

    if (!Object.keys(accountData.brands).length) {
      CSVRows.push('"' + Object.values(rowAccount).join('","') + '"\n');
    } else {
      Object.entries(accountData.brands).forEach(([brandSID, brandData]) => {
        const rowBrand = { ...rowAccount };
        rowBrand["Brand SID"] = brandSID;
        rowBrand["Brand Name"] = brandData.friendly_name;
        rowBrand["Brand Status"] = brandData.status;
        rowBrand["Brand Failure Reason"] = brandData.failure_reason;
        rowBrand["Brand Type"] = brandData.type;
        rowBrand["Company HubSpot ID"] = brandData.hubspot_id;
        rowBrand["Brand HubSpot ID"] = brandData.hs_id;
        rowBrand["Brand HubSpot Sync"] = brandData.hs_sync;
        rowBrand["Missing Campaign"] = brandData.isMissingCampaign;

        if (!Object.keys(brandData.campaigns).length) {
          CSVRows.push('"' + Object.values(rowBrand).join('","') + '"\n');
        } else {
          Object.entries(brandData.campaigns).forEach(
            ([messagingServiceSID, campaignData]) => {
              const rowCampaign = { ...rowBrand };
              rowCampaign["Campaign Created"] = campaignData.isCreated;
              rowCampaign["Messaging Service SID"] = messagingServiceSID;
              rowCampaign["Campaign ID"] = campaignData.id;
              rowCampaign["Campaign Status"] = campaignData.status;
              rowCampaign["Campaign HubSpot ID"] = campaignData.hs_id;
              rowCampaign["Campaign HubSpot Sync"] = campaignData.hs_sync;
              rowCampaign["Campaign Date Created"] = campaignData.dateCreated;
              rowCampaign["Campaign Date Updated"] = campaignData.dateUpdated;

              if (!Object.keys(campaignData.phoneNumbers).length) {
                CSVRows.push(
                  '"' + Object.values(rowCampaign).join('","') + '"\n'
                );
              } else {
                Object.entries(campaignData.phoneNumbers).forEach(
                  ([phoneNumberSID, phoneNumberData]) => {
                    const rowPhoneNumber = { ...rowCampaign };
                    rowPhoneNumber["Phone Number SID"] = phoneNumberSID;
                    rowPhoneNumber["Phone Number"] =
                      phoneNumberData.phoneNumber;
                    rowPhoneNumber["Phone Number Status"] =
                      phoneNumberData.status;
                    rowPhoneNumber["Rooftop HubSpot ID"] =
                      phoneNumberData.hs_id;
                    rowPhoneNumber["Rooftop HubSpot Sync"] =
                      phoneNumberData.hs_sync;

                    CSVRows.push(
                      '"' + Object.values(rowPhoneNumber).join('","') + '"\n'
                    );
                  }
                );
              }
            }
          );
        }
      });
    }
  });

  onAddOutput(`CSV file is ready to download`);

  return CSVRows.join("");
}
